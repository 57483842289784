toastr.options = {
    "positionClass": "toast-top-center"
};

$(document).ready(function () {

    // Add body-small class if window less than 768px
    if ($(this).width() < 769) {
        $('body').addClass('body-small')
    } else {
        $('body').removeClass('body-small')
    }

    // MetsiMenu
    $('#side-menu').metisMenu();

    // Collapse ibox function
    $('.collapse-link').on('click', function () {
        var ibox = $(this).closest('div.ibox');
        var button = $(this).find('i');
        var content = ibox.find('div.ibox-content');
        content.slideToggle(200);
        button.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
        ibox.toggleClass('').toggleClass('border-bottom');
        setTimeout(function () {
            ibox.resize();
            ibox.find('[id^=map-]').resize();
        }, 50);
    });

    // Close ibox function
    $('.close-link').on('click', function () {
        var content = $(this).closest('div.ibox');
        content.remove();
    });

    $('.chosen-select').chosen({
        width: '100%',
        disable_search_threshold: 10,
        no_results_text: "没找到您搜索的项！",
        placeholder_text_single: '请选择...'
    });

    // 绑定搜索按钮回车事件
    $('#filter-keyword').keyup(function(event) {
        if (event.keyCode == 13) {
            $('#filter-btn').click();
            return false;
        }
    });

    // icheck
    $('.i-checks').iCheck({
        checkboxClass: 'icheckbox_square-green',
        radioClass: 'iradio_square-green'
    });

    // check all
    $('#check-all').on('ifChecked ifUnchecked', function(event) {
        if (event.type == 'ifChecked') {
            $('input[name="ids[]"]').iCheck('check');
        } else {
            $('input[name="ids[]"]').iCheck('uncheck');
        }
    });

    // Fullscreen ibox function
    $('.fullscreen-link').on('click', function () {
        var ibox = $(this).closest('div.ibox');
        var button = $(this).find('i');
        $('body').toggleClass('fullscreen-ibox-mode');
        button.toggleClass('fa-expand').toggleClass('fa-compress');
        ibox.toggleClass('fullscreen');
        setTimeout(function () {
            $(window).trigger('resize');
        }, 100);
    });

    // Minimalize menu
    $('.navbar-minimalize').on('click', function () {
        $("body").toggleClass("mini-navbar");
        SmoothlyMenu();

    });

    // Full height of sidebar
    function fix_height() {
        var heightWithoutNavbar = $("body > #wrapper").height() - 61;
        $(".sidebard-panel").css("min-height", heightWithoutNavbar + "px");

        var navbarHeigh = $('nav.navbar-default').height();
        var wrapperHeigh = $('#page-wrapper').height();

        if (navbarHeigh > wrapperHeigh) {
            $('#page-wrapper').css("min-height", navbarHeigh + "px");
        }

        if (navbarHeigh < wrapperHeigh) {
            $('#page-wrapper').css("min-height", $(window).height() + "px");
        }

        if ($('body').hasClass('fixed-nav')) {
            if (navbarHeigh > wrapperHeigh) {
                $('#page-wrapper').css("min-height", navbarHeigh + "px");
            } else {
                $('#page-wrapper').css("min-height", $(window).height() - 60 + "px");
            }
        }

    }

    fix_height();

    // Fixed Sidebar
    $(window).bind("load", function () {
        if ($("body").hasClass('fixed-sidebar')) {
            $('.sidebar-collapse').slimScroll({
                height: '100%',
                railOpacity: 0.9
            });
        }
    });

    $(window).bind("load resize scroll", function () {
        if (!$("body").hasClass('body-small')) {
            fix_height();
        }
    });

    // Add slimscroll to element
    $('.full-height-scroll').slimscroll({
        height: '100%'
    });

    $.validator.setDefaults({
        highlight: function (element) {
            $(element).closest('.form-group').removeClass('has-success').addClass('has-error');
        },
        success: function (element) {
            element.closest('.form-group').removeClass('has-error').addClass('has-success');
        },
        errorElement: "span",
        errorClass: "help-block m-b-none",
        validClass: "help-block m-b-none"
    });

    //validate & ajaxSubmit
    $('.ajax-form').validate({
        submitHandler:function(form) {
            var $submit = $(form).find('button:submit');
            $submit.attr('disabled', 'disabled');
            $(form).ajaxSubmit({
                type: form.method,
                dataType: 'json',
                success: function(res){
                    if(res.error === true){
                        toastr.error(res.message);
                        $submit.attr('disabled', '');
                    }else{
                        toastr.success(res.message);

                        if (res.url == null || res.url == '') {
                            //不带连接
                            setTimeout(function(){
                                window.location.reload();
                            }, 2000);
                        } else {
                            //带连接
                            setTimeout(function(){
                                window.location.href = res.url;
                            }, 2000);

                        }

                    }

                }
            });
        }

    });

    $('.modal-submit').on('click',function(){
        $modal = $(this).parents('.modal');
        $(this).parents('.modal-form').ajaxSubmit({
            //type: form.method,
            dataType: 'json',
            success: function(res){
                if(res.error === true){
                    toastr.error(res.message);
                }else{
                    toastr.success(res.message);

                    $modal.modal('hide');
                    if (res.url != null || res.url != '') {
                        //带连接
                        setTimeout(function(){
                            window.location.href = res.url;
                        }, 2000);
                    }

                }

            }
        });
    });

    $('.ajax-delete').click(function(){
        if(confirm("你确定要删除吗？")) {
            var url = $(this).data('delete');
            var row = $(this).parent().parent();
            $.ajax({
                url: url,
                type: 'DELETE',
                dataType: 'json',
                headers:{'X-CSRF-TOKEN':$('meta[name="csrf-token"]').attr('content')},
                success: function(res){
                    if(res.error === true) {
                        toastr.error(res.message);
                    }else{
                        row.remove();
                        toastr.success(res.message);
                    }
                },
                error: function(XMLHttpRequest, textStatus, errorThrown){
                    toastr.error(errorThrown);
                }
            });
        }

        return false;
    });

    $('.ajax-destruct').click(function(){
        if(confirm("你确定要删除吗？")) {
            var url = $(this).data('destruct') + '?ids=';
            $('input[name="ids[]"]:checkbox').each(function(){
                if($(this).is(':checked')){
                    url += $(this).val()+",";
                }
            });
            url = url.substr(0, url.length-1);

            $.ajax({
                url: url,
                type: 'GET',
                dataType: 'json',
                headers:{'X-CSRF-TOKEN':$('meta[name="csrf-token"]').attr('content')},
                success: function(res){
                    if(res.error === true) {
                        toastr.error(res.message);
                    }else{
                        toastr.success(res.message);

                        setTimeout(function(){
                            window.location.reload();
                        }, 2000);
                    }

                },
                error: function(XMLHttpRequest, textStatus, errorThrown){
                    toastr.error(errorThrown);
                }
            });
        }

        return false;
    });

});


// Minimalize menu when screen is less than 768px
$(window).bind("resize", function () {
    if ($(this).width() < 769) {
        $('body').addClass('body-small')
    } else {
        $('body').removeClass('body-small')
    }
});

function SmoothlyMenu() {
    if (!$('body').hasClass('mini-navbar') || $('body').hasClass('body-small')) {
        // Hide menu in order to smoothly turn on when maximize menu
        $('#side-menu').hide();
        // For smoothly turn on menu
        setTimeout(
            function () {
                $('#side-menu').fadeIn(400);
            }, 200);
    } else if ($('body').hasClass('fixed-sidebar')) {
        $('#side-menu').hide();
        setTimeout(
            function () {
                $('#side-menu').fadeIn(400);
            }, 100);
    } else {
        // Remove all inline style from jquery fadeIn function to reset menu state
        $('#side-menu').removeAttr('style');
    }
}
